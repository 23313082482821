import React, { forwardRef } from 'react';
import { useSwiper } from 'swiper/react';
import { ChevronLeft, ChevronRight } from 'react-feather';

export const SliderButton = forwardRef<
  HTMLButtonElement,
  { position: 'left' | 'right'; hidden?: boolean }
>(({ position, hidden }, ref) => {
  const swiper = useSwiper();
  const style = position === 'left' ? `left-5 right-auto` : `right-5 left-auto`;

  return (
    <button
      ref={ref}
      onClick={() =>
        position == 'left' ? swiper.slidePrev() : swiper.slideNext()
      }
      className={`invisible sm:visible absolute top-1/2 ${style} z-10 rounded-full bg-white w-10 h-10 flex justify-center items-center cursor-pointer ${
        hidden ? 'opacity-0' : 'opacity-70'
      } transition-opacity duration-1000 ease-out `}
    >
      {position == 'left' ? <ChevronLeft /> : <ChevronRight />}
    </button>
  );
});
