const markDefs = `// groq
    markDefs[]{
        ...,
        _type == "internalLink" => {
            ...,
            "document": @.reference->{_type, "slug": slug.current, "route": route.current},
        }
    }
`;

const imageField = `// groq
    ...,
    "dimensions": asset-> {
        "width": metadata.dimensions.width,
        "height": metadata.dimensions.height
    }
`;

const seoField = `// groq
    ...,
    openGraphImage {
        ${imageField}
    }
`;

export const contentField = `// groq
    content[]{
        ...,
        _type == "faqSection" => {
            questions[]-> {
                _id,
                _type,
                question,
                answer[] {
                    ...,
                    ${markDefs}
                }
            },
        },
        _type == "articleList" => {
            title,
            articles[] -> {
                _id,
                title,
                "slug": slug.current,
                image {
                    ${imageField}
                }
            }
        }
    }
`;

export const articleQuery = `// groq
*[_type == "article" && slug.current == $slug] {
    ...,
    image {
        ${imageField}
    },
    intro[]{
        ...,
        ${markDefs}
    },
    body[]{
        ...,
        _type == "figure" => {
            ${imageField}
        },
        _type == "articleFigure" => {
            ...,
            figure {
                ${imageField}
            }
        },
        _type == "cta"[] => {
            ...,
            internalLink->{_type, "slug": slug.current, "route": route.current}
        },
        ${markDefs}
    },
    categories[] -> {
        _id,
        title,
        "slug": slug.current
    },
    ${contentField}
}[0]
`;

export const startPageQuery = `// groq
    *[_id == "startPage"] {
        curations[]->
    }[0]
`;

export const giftCardPageQuery = `// groq
    *[_id == "giftCardPage"] {
        title,
        heading,
        intro,
        seo {
            ${seoField}
        },
        image {
            ${imageField}
        },
        fixedAmounts,
        ${contentField}
    }[0]
`;

export const editionsPageQuery = `// groq
    *[_type == "editionsPage" && (location == $location || _id == "editionsPage")][0] {
        ...,
        seo {
            ${seoField}
        }
    }
`;

export const releaseNotesQuery = `// groq
    *[_type == "releaseNote"][0..10] {
        ...,
        summary[]{
            ...,
            ${markDefs}
        },
        body[]{
            ...,
            ${markDefs}
        }
    }
`;
