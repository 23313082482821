import React, { useEffect, useState } from 'react';
import { Scrollbar, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SliderButton } from './sliderButton';
import { SanityCurationItem } from '@lib/sanity.server';
import { Heading } from '@components/ui/Heading';
import firebase from '@lib/firebase';
import { SliderCard } from './sliderCards';
// Styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/mousewheel';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * Returns a single row slider with buttons (desktop), supported cards are handled by SliderCard
 */
function SingleRowCardSlider({
  curationName,
  items,
  userAuth,
  hover,
}: {
  curationName: string;
  items: SanityCurationItem[] | any[];
  userAuth?: { user: firebase.User | null; authenticated: boolean };
  hover: boolean;
}) {
  const [cardWidth, setCardWidth] = useState(200);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    const { width } = getWindowDimensions();
    const w = width < 480 ? 150 : 200;
    setCardWidth(w);
  }, []);

  return (
    <div>
      <Swiper
        modules={[Scrollbar, Mousewheel]}
        onSlideChange={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        enabled
        mousewheel={{ forceToAxis: true }}
        scrollbar={{ dragSize: 'auto', snapOnRelease: false }}
        slidesPerView={'auto'}
        spaceBetween={16}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        className="pb-20"
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={`s--${index}`}
            className="self-baseline"
            style={{ width: 'auto' }}
          >
            <SliderCard
              cardWidth={cardWidth}
              userAuth={userAuth}
              curationName={curationName}
              item={item}
              index={index}
            />
          </SwiperSlide>
        ))}

        {!isBeginning && (
          <SliderButton
            hidden={!hover}
            position="left"
            ref={navigationPrevRef}
          />
        )}
        {!isEnd && items.length > 1 && (
          <SliderButton
            hidden={!hover}
            position="right"
            ref={navigationNextRef}
          />
        )}
      </Swiper>
    </div>
  );
}

/**
 * Returns a curation section with heading, desciption and slider with cards
 */
export function CurationSection({
  title,
  description,
  items,
  userAuth,
}: {
  title: string;
  description: string | undefined;
  items: SanityCurationItem[];
  userAuth?: { user: firebase.User | null; authenticated: boolean };
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <>
      <div className="mb-4">
        <Heading level="h2" size="lg" className="mb-1">
          {title}
        </Heading>
        {description && (
          <p className="max-w-screen-md text-gray-600">{description}</p>
        )}
      </div>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <SingleRowCardSlider
          curationName={title}
          items={items}
          userAuth={userAuth}
          hover={isHover}
        />
      </div>
    </>
  );
}

export function EditionsSection({
  title,
  description,
  items,
  userAuth,
}: {
  title: string;
  description: string | React.ReactNode | undefined;
  items: any[];
  userAuth?: { user: firebase.User | null; authenticated: boolean };
}) {
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <div className="mb-4">
        <Heading level="h2" size="lg" className="mb-1">
          {title}
        </Heading>
        {description && (
          <p className="max-w-screen-md text-gray-600">{description}</p>
        )}
      </div>
      <div
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <SingleRowCardSlider
          curationName={title}
          items={items}
          userAuth={userAuth}
          hover={isHover}
        />
      </div>
    </>
  );
}
