import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useSwiperSlide } from 'swiper/react';
import 'swiper/css';
import Image from 'next/image';
import { Button } from '@components/ui/Button';
import { SanityCurationItem } from '@lib/sanity.server';
import { StudioLink } from '@components/common/routing';
import { ArtworkModalLink } from '@components/artwork/ArtworkModal';
import { useAuthModal } from '@components/auth/AuthModal';
import { AtelierLink } from '@components/common/routing';
import {
  useArtworkImpressionTracker,
  trackFavouriteStudio,
  trackRemoveFavouriteStudio,
} from '@lib/analytics';
import firebase from '@lib/firebase';
import {
  EditionsListProductFragment,
  useCuratedArtistQuery,
  useCuratedArtworkQuery,
  ImpressionArtworkItemFragment,
  useFollowingStudioQuery,
  useToggleFollowingStudioMutation,
  FollowingStudioQuery,
  FollowingStudioQueryVariables,
} from '@generated/codegen';
import { ArtworkPrice } from '@components/artwork/ArtworkView/ArtworkPrice';
import { CURRENCY } from '@shared/enums';

const FOLLOWING_STUDIO_QUERY = gql`
  query followingStudio($studioId: ID!) {
    currentUser {
      id
      followingStudio(studioId: $studioId)
    }
  }
`;

gql`
  mutation toggleFollowingStudio($studioId: ID!) {
    toggleFollowingStudio(studioId: $studioId)
  }
`;

function calculateImageSize(
  maxWidth: number,
  image: { width: number; height: number }
) {
  const ratio = image.height / image.width;
  const width = maxWidth;
  const height = maxWidth * ratio;

  return { width: width, height };
}

/**
 * Slider card for artist
 */
function SliderCardArtwork({
  index,
  artworkId,
  onArtworkImpression,
  onArtworkSelect,
  cardWidth,
}: {
  index: number;
  artworkId: string;
  onArtworkImpression: (
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) => void;
  onArtworkSelect: (
    artwork: ImpressionArtworkItemFragment,
    index: number
  ) => void;
  cardWidth: number;
}) {
  const { data } = useCuratedArtworkQuery({
    variables: { artworkId },
  });
  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    data?.artwork && onArtworkImpression(data.artwork, index);
  }, [swiperSlide.isActive]);

  if (
    !data ||
    !data?.artwork ||
    !data.artwork.studio ||
    !data?.artwork.studio.permalinkOrId ||
    data?.artwork?.images.length == 0
  ) {
    return null;
  }

  const { artwork } = data;
  const { id, studio, images } = artwork;
  const image = images[0];
  const { width, height } = calculateImageSize(cardWidth, image);

  if (!studio?.permalinkOrId) {
    return null;
  }

  return (
    <div className={`flex flex-col relative pb-10`}>
      <ArtworkModalLink
        artworkId={id}
        studioPermalinkOrId={studio.permalinkOrId}
      >
        <a
          title={artwork.title}
          className="block hover:underline"
          onClick={() => {
            onArtworkSelect(artwork, index);
          }}
        >
          <div className={`relative mb-1 `} style={{ width: width }}>
            <Image
              src={image.url}
              alt={`${artwork.title} by ${studio?.name}`}
              layout="fixed"
              priority={true}
              height={height}
              width={width}
            />
          </div>
        </a>
      </ArtworkModalLink>
      <div className="flex flex-col" style={{ width: width }}>
        {studio?.name && (
          <StudioLink permalinkOrId={studio?.permalinkOrId}>
            <a className="block truncate hover:underline font-bold">
              {studio?.name}
            </a>
          </StudioLink>
        )}
        <p className="truncate text-sm text-gray-600 ">{artwork.title}</p>
        {artwork.formattedPrice && (
          <>
            {ArtworkPrice(
              artwork.basePrice ?? 0,
              artwork.bkhPrice ?? 0,
              artwork.currency === 'EUR' ? CURRENCY.EUR : CURRENCY.NOK
            )}
          </>
        )}
      </div>
    </div>
  );
}
/**
 * Slider card for artist
 */
function SliderCardEditions({
  data,
  cardWidth,
}: {
  data: EditionsListProductFragment;
  cardWidth: number;
}) {
  if (!data?.image?.src) {
    return null;
  }

  const { width, height } =
    data?.image && calculateImageSize(cardWidth, data.image);

  return (
    <div className={`flex flex-col relative pb-10`}>
      <AtelierLink
        href={{
          pathname: '/editions/[slug]',
          query: { slug: data.slug },
        }}
      >
        <a className="cursor-pointer">
          <a
            title={data.name}
            className="block hover:underline"
            onClick={() => {
              // onArtworkSelect(artwork, index);
            }}
          >
            <div className={`relative mb-1 `} style={{ width: width }}>
              <Image
                src={data?.image?.src}
                alt={`Atelie Edition - ${data.name}`}
                layout="fixed"
                priority={true}
                height={height}
                width={width}
              />
            </div>
          </a>
          <div className="flex flex-col" style={{ width: width }}>
            {data?.studio?.name && (
              <a className="block truncate  font-bold">{data?.studio?.name}</a>
            )}
            {data?.name && (
              <p className="truncate text-sm text-gray-600 ">{data?.name}</p>
            )}
            {data?.oneTimePrice?.formattedPrice?.formatted && (
              <p className="text-sm text-gray-600 ">
                {data.oneTimePrice.formattedPrice.formatted}
              </p>
            )}
          </div>
        </a>
      </AtelierLink>
    </div>
  );
}
/**
 * Slider card for artwork
 */
function SliderCardArtist({
  userAuth,
  artistId,
}: {
  userAuth?: { user: firebase.User | null; authenticated: boolean };
  artistId: string;
}) {
  const artist = useCuratedArtistQuery({
    variables: { artistId },
  });
  const userId = userAuth?.user?.uid;
  const studioId = artist?.data?.studio?.id;
  const isOwner = studioId === userId;

  const followingStudio = useFollowingStudioQuery({
    variables: { studioId: studioId || '' },
    skip: !studioId || !userAuth?.authenticated || isOwner,
    ssr: false,
  });

  const isFollowing =
    followingStudio?.data &&
    followingStudio.data.currentUser &&
    followingStudio.data.currentUser.followingStudio;

  // TODO: Tracking can be moved to backend - this functions should be simplified
  const [toggleFollowingStudio, { loading: toggleFollwingLoading }] =
    useToggleFollowingStudioMutation({
      optimisticResponse: {
        __typename: 'Mutation',
        toggleFollowingStudio: !isFollowing,
      },
      update: (cache, { data }) => {
        if (!data || !studioId) {
          return;
        }
        const currentUserResult = cache.readQuery<
          FollowingStudioQuery,
          FollowingStudioQueryVariables
        >({
          query: FOLLOWING_STUDIO_QUERY,
          variables: { studioId },
        });
        if (!currentUserResult || !currentUserResult.currentUser) {
          return;
        }
        cache.writeQuery<FollowingStudioQuery, FollowingStudioQueryVariables>({
          query: FOLLOWING_STUDIO_QUERY,
          variables: { studioId },
          data: {
            ...currentUserResult,
            currentUser: {
              ...currentUserResult.currentUser,
              followingStudio: data.toggleFollowingStudio,
            },
          },
        });
      },
      onCompleted: (data) => {
        if (!data || !studioId) {
          return;
        }
        if (data.toggleFollowingStudio) {
          trackFavouriteStudio({ studioId: studioId });
        } else {
          trackRemoveFavouriteStudio({ studioId: studioId });
        }
      },
    });

  const { openAuthModal } = useAuthModal();

  if (!artist || !artist.data || !artist.data.studio) {
    return null;
  }

  const { studio } = artist.data;
  if (!studio.bannerImage?.src) {
    return null;
  }
  const imageSrc = studio.bannerImage?.src;
  if (!imageSrc || !imageSrc.startsWith('/')) {
    return null;
  }
  const height = 200;

  return (
    <div className={`relative pb-10`}>
      <StudioLink permalinkOrId={studio?.permalinkOrId}>
        {studio.name && imageSrc && (
          <div className="relative cursor-pointer" style={{ height: height }}>
            <Image
              src={imageSrc}
              alt={studio.name}
              layout="fill"
              priority={true}
              objectFit="cover"
              sizes={`${height}px`}
            />
          </div>
        )}
      </StudioLink>
      <div className={`flex flex-col w-full mt-2 justify-center`}>
        <p className="text-center font-bold">{studio.name}</p>
        <p className="text-center text-sm">Artist</p>

        <Button
          onClick={() => {
            if (!userAuth?.authenticated) {
              openAuthModal(
                `Logg inn for å følge ${studio.name || 'kunstneren'}`
              );
            } else {
              toggleFollowingStudio({ variables: { studioId: studio.id } });
            }
          }}
          loading={toggleFollwingLoading}
          disabled={!!isFollowing || !studioId}
          size="sm"
          variant={isFollowing ? 'outline' : 'primaryYellow'}
          className="self-center mt-2"
        >
          {isFollowing ? 'Følger' : 'Følg'}
        </Button>
      </div>
    </div>
  );
}
/**
 * Return correct cart for curation item
 * Currently supports artist and artwork
 */
export function SliderCard({
  curationName,
  item,
  index,
  userAuth,
  cardWidth,
}: {
  curationName: string;
  item: SanityCurationItem | any;
  index: number;
  userAuth?: { user: firebase.User | null; authenticated: boolean };
  cardWidth: number;
}) {
  const { onArtworkImpression, onArtworkSelect } = useArtworkImpressionTracker(
    'curation',
    curationName
  );

  if (item._type) {
    switch (item._type) {
      case 'artist': {
        const { artistId } = item;
        return <SliderCardArtist userAuth={userAuth} artistId={artistId} />;
      }
      case 'artwork': {
        const { artworkId } = item;
        return (
          <SliderCardArtwork
            cardWidth={cardWidth}
            index={index}
            artworkId={artworkId}
            onArtworkImpression={onArtworkImpression}
            onArtworkSelect={onArtworkSelect}
          />
        );
      }

      default: {
        console.error('Unknown item type!', { item });
        return null;
      }
    }
  }
  if (item?.__typename === 'ProductEditions') {
    return <SliderCardEditions cardWidth={cardWidth} data={item} />;
  }

  console.error('Card is not configured for this item!', { item });
  return null;
}
